import styles from '@/styles/navbar.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import { DownOutlined } from '@ant-design/icons';
import SearchBar from './SearchBar';
import { useState, useEffect } from 'react';
import myAccountApi from '@/service/modules/myAccountApi'
import { localStorageService, sessionStorageService } from "@/utils/storageService"
import { setCookie, removeCookie } from '@/utils/cookie'
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;
const imageDomain = publicRuntimeConfig.imageDomain

import { useRouter } from 'next/router';
import { Button, Skeleton } from 'antd';
interface props {
  wideScreen?: boolean;
  categoryData?: any;
  useChristmasTheme?: boolean;
  sticky?: boolean;
}

interface ArrayItem {
  pic: string;
  name: string;
  Quantity: number | string;
  Price: number | string;
  select_status: number | string

}
interface Content {
  content: Array<ArrayItem>;
  products: string | number;
  subTotal: string | number;
}

interface CartData {
  cart: Content;
}

export default function Navbar({ wideScreen, categoryData, useChristmasTheme, sticky }: props) {
  const [count, setCount] = useState(0);
  const [minicarList, setMinicarList] = useState<Array<ArrayItem>>([]);
  const [carInfo, setCarInfo] = useState<Content>();

  // 外链跳转列表
  const extLinkList = [
    {
      title: 'Tiktok',
      link: 'https://www.tiktok.com/@crazysalesaucom',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/svg/social/icon-share-tiktok-color.svg'
    },
    {
      title: 'Facebook',
      link: 'https://www.facebook.com/CrazySales.com.au/',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/svg/social/icon-social-fb-color.svg'
    },
    {
      title: 'Youtube',
      link: 'https://www.youtube.com/@AUSWay',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/svg/social/icon-social-ytb-color.svg'
    },
    {
      title: 'Twitter',
      link: 'https://twitter.com/CrazySalesAus',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/svg/social/icon-social-tw-color.svg'
    },
    {
      title: 'Instagram',
      link: 'https://www.instagram.com/crazysalescomau/',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/svg/social/icon-social-ins-color.svg'
    },
    {
      title: 'Pinterest',
      link: 'https://au.pinterest.com/crazysales/',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/svg/social/icon-social-pin-color.svg'
    },
    {
      title: 'Blog',
      link: 'https://blog.crazysales.com.au/',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/svg/social/icon-social-bg-color.svg'
    }
  ]
  const extLinkItems = extLinkList.map(ext =>
    <Link
      key={ext.title}
      href={ext.link}
      className={styles['ext-link-item']}
      passHref
      legacyBehavior
    >
      <a title={ext.title} target="_blank" rel="noopener noreferrer">
        <Image
          className={styles['ext-link-svg']}
          alt="CrazyPoints"
          width={24}
          height={24}
          src={ext.imageUrl} />
      </a>
    </Link>
  )

  const [isLogin, setIsLogin]: any = useState(false)

  // 退出登录，移除token信息
  function removeToken() {
    localStorage.removeItem('tokenExpired')
    localStorage.removeItem('userToken')
  }

  // 页面跳转
  function handlePageJump(url: string) {
    window.location.href = `${apiDomain}${url}`
  }

  // 轮询刷新token、延续Session时长
  useEffect(() => {
    const timer = setInterval(async () => {
      if (
        localStorage.getItem('userToken')
        && localStorage.getItem('tokenExpired')
        && (Number(localStorage.getItem('tokenExpired')) <= new Date().valueOf())
      ) {
        try {
          let res = await myAccountApi.getCurrentCustomerInfo()
          if (res.data && res.data.token) {
            let timeStamp = new Date().valueOf() + (10 * 60 * 1000)
            localStorage.setItem('tokenExpired', timeStamp.toString())
            localStorage.setItem('userToken', `Bearer ${res.data.token}`)
            localStorage.setItem('userEmail', res.data.email || '')

            setCookie('userCustomerId', res.data.customerID || '')
          } else if (res.data && !res.data.token) {
            localStorage.removeItem('tokenExpired')
            localStorage.removeItem('userToken')
            localStorage.removeItem('userEmail')
            removeCookie('userCustomerId')
          }
        } catch (error) {
          localStorage.removeItem('userEmail')
          console.error(error)
        }
      }
    }, 0.2 * 60 * 1000); // 10分钟，单位为毫秒

    // 在组件卸载时销毁定时器
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    // 获取用户信息，如果有用户名返回，则代表已登录，反之未登录
    getUserInfo()
    // emarsys tracking数据推送
    setEmarsysEmail()

    window.dispatchEvent(new Event('storageUpdated'));
    countShopCarNum()
    const handleStorageChange = (event: any) => {
      if (event.key == 'copyCartData') {
        countShopCarNum()
        dealWithMiniCart()
      }

    };

    const handleStorageUpdated = () => {
      countShopCarNum()
      dealWithMiniCart()
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('storageUpdated', handleStorageUpdated);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('storageUpdated', handleStorageUpdated);
    };
  }, []);

  // 处理minicar
  function dealWithMiniCart() {
    let cartData: any = localStorageService.getItem('cartData')
    if (cartData) {
      cartData = cartData ? JSON.parse(cartData) : cartData
      const data: CartData = cartData
      const { cart, cart: { content } } = data
      setCarInfo(cart)
      const newCarList = content.filter((item: ArrayItem) => item.select_status == 1)
      setMinicarList(newCarList)
    }
  }

  // 计算购物车数量
  function countShopCarNum() {
    let myData = localStorageService.getItem('copyCartData')
    myData = myData ? JSON.parse(myData) : myData
    if (!Array.isArray(myData)) return
    let count = 0
    myData.filter((v: any) => {
      return v.selected == '1'
    }).forEach((v: any) => {
      // 去掉数量，后面是以sku为数量
      // count += parseInt(v.qty)
      count++
    })
    setCount(count)
  }

  const [firstname, setFirstname] = useState('')
  // 请求用户数据
  const getUserInfo = async () => {
    try {
      setFirstname('')
      // 这里把header抽出来，需要填授权字段Authorization进去
      let headers: {
        'X-Requested-With': string;
        'Content-Type': string;
        Accept: string;
        Authorization?: string;
        'X-Service-Group': string;
        'X-Certain-Group': string;
      } = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'X-Service-Group': 'BG',
        'X-Certain-Group': 'BG',
        Accept: '*/*',
      };

      const token = localStorage.getItem('userToken');
      if (token !== null) {
        headers['Authorization'] = token as string;
      }

      const response = await fetch(`${apiDomain}/api/getCurrentCustomerInfo`, {
        method: 'GET',
        credentials: 'include',
        headers,
        mode: 'cors'
      })

      if (!response.ok) {
        throw new Error('请求出错：' + response.statusText);
      }

      const userInfo: any = await response.json()
      if (userInfo.data && userInfo.data.firstname) {
        setIsLogin(true)
        setFirstname(userInfo.data.firstname)
        sessionStorageService.setItem('had_login', '1')

        if (userInfo.data.token) {
          localStorage.setItem('userToken', `Bearer ${userInfo.data.token}`)

          localStorage.setItem('userEmail', userInfo.data.email || '')
          setCookie('userCustomerId', userInfo.data.customerID || '')
        } else {
          localStorage.removeItem('userEmail')
          removeCookie('userCustomerId')
        }
      } else {
        setIsLogin(false)
        sessionStorageService.setItem('had_login', '0')
        localStorage.removeItem('userEmail')
        removeCookie('userCustomerId')
      }

      console.log(response)

    } catch (error) {
      sessionStorageService.setItem('had_login', '0')
      localStorage.removeItem('userEmail')
      removeCookie('userCustomerId')
      console.log(error)
    }
  }

  // Navbar滚动监听, 滑下去就把导航fixed
  const [navFixed, setNavFixed] = useState(false)
  // 调整侧边Subscribe位置
  function adjustSliderPosition(isFixed: string) {
    const pageDeliveryBrand = document.querySelector(".slider-wapper")
    const sliderBarLeft = document.getElementById('sliderBarLeft')
    const sliderBarRight = document.getElementById('sliderBarRight')
    const footerBox = document.getElementById('footerBox')

    if (isFixed) {
      if (sliderBarLeft && pageDeliveryBrand && footerBox) {
        const pageDeliveryBrandRect = pageDeliveryBrand.getBoundingClientRect();
        const sliderBarLeftRect = sliderBarLeft.getBoundingClientRect();
        const footerBoxRect = footerBox.getBoundingClientRect();

        if (footerBoxRect.top - sliderBarLeftRect.bottom > 32 || sliderBarLeftRect.top > 98) {
          sliderBarLeft.style.position = "fixed";
          sliderBarLeft.style.top = "98px";
          sliderBarLeft.style.bottom = "auto";
          sliderBarLeft.style.left = `${pageDeliveryBrandRect.left - 105}px`;
        } else {
          sliderBarLeft.style.position = "absolute";
          sliderBarLeft.style.top = "auto";
          sliderBarLeft.style.bottom = "0px";
          sliderBarLeft.style.left = "-105px";
        }
      }

      if (sliderBarRight && pageDeliveryBrand && footerBox) {
        const pageDeliveryBrandRect = pageDeliveryBrand.getBoundingClientRect();
        const sliderBarRightRect = sliderBarRight.getBoundingClientRect();
        const footerBoxRect = footerBox.getBoundingClientRect();

        if (footerBoxRect.top - sliderBarRightRect.bottom > 32 || sliderBarRightRect.top > 98) {
          sliderBarRight.style.position = "fixed";
          sliderBarRight.style.top = "98px";
          sliderBarRight.style.bottom = "auto";
          sliderBarRight.style.left = `${pageDeliveryBrandRect.right + 10}px`;
        } else {
          sliderBarRight.style.position = "absolute";
          sliderBarRight.style.top = "auto";
          sliderBarRight.style.bottom = "0px";
          sliderBarRight.style.left = "1210px";
        }
      }
    } else {
      if (sliderBarLeft && pageDeliveryBrand) {
        sliderBarLeft.style.position = "absolute";
        sliderBarLeft.style.top = "20px";
        sliderBarLeft.style.bottom = "auto";
        sliderBarLeft.style.left = "-105px";
      }

      if (sliderBarRight && pageDeliveryBrand) {
        sliderBarRight.style.position = "absolute";
        sliderBarRight.style.top = "20px";
        sliderBarRight.style.bottom = "auto";
        sliderBarRight.style.left = "1210px";
      }
    }
  }
  const router = useRouter()
  // 不需要吸顶的黑名单
  useEffect(() => {
    // 监听滚动事件
    function checkNav() {
      let target = document.getElementById('menu') as Element
      if (target?.getBoundingClientRect().top <= 0) {
        // 设置了吸顶才setNavFixed(true)
        sticky && setNavFixed(true)
        adjustSliderPosition('fixed')
      } else {
        setNavFixed(false)
        adjustSliderPosition('')
      }

      // 控制圣诞主题 公仔展示
      let homeReviewsElement = document.getElementById('homeReviews') as Element
      let slideDollElement = document.getElementById('slideDoll') as HTMLElement;

      if (!slideDollElement) return
      if (homeReviewsElement?.getBoundingClientRect().top <= 0) {
        // 让slideDoll隐藏
        slideDollElement.style.opacity = '0';
      } else {
        // 让slideDoll展示
        slideDollElement.style.opacity = '1';
      }
    }

    window.addEventListener('scroll', checkNav)
    return () => {
      window.removeEventListener('scroll', checkNav)
    }
  }, [])

  // 监听resize事件
  useEffect(() => {
    function handleResize() {
      let target = document.getElementById('menu') as Element
      if (target?.getBoundingClientRect().top <= 0) {
        adjustSliderPosition('fixed')
      } else {
        adjustSliderPosition('')
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 请求接口，设置一些初始化的cookie
  const initCookie = async (aid: string | undefined, recoveraid: string | undefined) => {
    try {
      if (!aid && !recoveraid) return

      let fetchPath = `${apiDomain}/api/setCookie`
      if (aid) fetchPath = `${fetchPath}?aid=${aid}`
      if (recoveraid) fetchPath = `${fetchPath}?recoveraid=${recoveraid}`

      const response = await fetch(fetchPath, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'X-Service-Group': 'BG',
          'X-Certain-Group': 'BG'
        },
        mode: 'cors'
      })

      if (!response.ok) {
        throw new Error('请求出错：' + response.statusText);
      }
    } catch (error) {
      return { notFound: true }
    }
  }

  useEffect(() => {
    const { aid, recoveraid }: any = router.query
    let lastAidValue = ''
    let lastRecoveraidValue = ''

    // 检查是否有"aid"参数，如有多个取最后
    if (Array.isArray(aid) && aid.length > 0) {
      lastAidValue = aid[aid.length - 1]
    } else if (typeof aid === 'string') {
      // 如果只有一个"aid"参数，它将是一个字符串
      lastAidValue = aid
    }

    // 检查是否有"recoveraid"参数，如有多个取最后
    if (Array.isArray(recoveraid) && recoveraid.length > 0) {
      lastRecoveraidValue = recoveraid[recoveraid.length - 1]
    } else if (typeof recoveraid === 'string') {
      // 如果只有一个"recoveraid"参数，它将是一个字符串
      lastRecoveraidValue = recoveraid
    }

    initCookie(lastAidValue, lastRecoveraidValue)
  }, [router.query])

  /**
   * emarsys tracking数据推送
   */
  const setEmarsysEmail = () => {
    // 发送traking之前，先判断路由是不是需要特殊处理的页面，是就return
    let specialTrackingList = [
      '/product-detail/[product]',
      '/category/[...slug]',
      '/search/[...slug]',
      '/shop/[...slug]'
    ]
    let target = specialTrackingList.filter((item: any) => item == router.pathname)
    if (target.length) return

    const subdomain = document.location.protocol === 'https:' ? 'https://recommender' : 'http://cdn';
    const id = 'scarab-js-api';

    if (document.getElementById(id)) return;

    const js = document.createElement('script');
    js.id = id;
    js.src = `${subdomain}.scarabresearch.com/js/1CBC47CFE1C81625/scarab-v2.js`;

    js.onload = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
            var ScarabQueue = ScarabQueue || [];
            if (localStorage.getItem('userEmail')) {
              ScarabQueue.push(['setEmail', localStorage.getItem('userEmail')]);
            }
            ScarabQueue.push(['go']);
        `;

      document.body.appendChild(script);
    };

    const fs = document.getElementsByTagName('script')[0];
    fs?.parentNode?.insertBefore(js, fs);
  }

  return (
    <>
      <div 
        className={styles['navbar']}
        style={useChristmasTheme ? {
          height: '114px',
          background: useChristmasTheme ? `url('${imageDomain}/christmas-theme/web-top-bg-narrow.jpg') no-repeat` : '',
          backgroundColor: '#ffffff',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        } : undefined}
      >
        <div className={`${styles['navbar-box']} ${navFixed ? styles['fixed-navbar'] : ''}`} id="navbar-box">
          <div className={`${styles['navbar-content']} ${wideScreen ? styles['navbar-content-wide'] : ''} `}>
            {/* logo */}
            <div className={styles['logo-box']}>
              <Link
                href='/'
                legacyBehavior
              >
                <a rel="noopener noreferrer">
                  <Image
                    className={styles['site-logo']}
                    alt="logo"
                    priority={true}
                    width={246}
                    height={70}
                    src="//static.crazysales.com.au/images/2022v/img/logo.svg" />
                </a>
              </Link>

            </div>

            {/* 右边的内容 */}
            <div className={styles['right-box']}>
              <div className={styles['link-box']}>
                <div className={styles['shop-share']}>
                  {extLinkItems}
                </div>

                <div className={styles['track-order']}>|
                  <Link
                    href={`/Login?r=/myaccount/order/0`}
                    legacyBehavior
                  >
                    <a rel="noopener noreferrer">
                      <span>Track My Order</span>
                    </a>
                  </Link>
                </div>

                <div className={styles['help-link']}>
                  |<span className={styles['nav']}>Help<DownOutlined className={styles['icon-down']} /></span>
                  <div className={styles['menu']}>
                    <Link
                      className={styles['link']}
                      href={`${apiDomain}/legal/faq`}
                      passHref
                      legacyBehavior
                    >FAQ</Link>
                    <Link
                      className={styles['link']}
                      href={`${apiDomain}/contact-us`}
                      passHref
                      legacyBehavior
                    >Contact Us</Link>
                  </div>
                </div>

                <div className={styles['app-link']}>|
                  <i className={`iconfont csicon-app ${styles['icon-app']}`} />
                  <Link
                    href={`/app_guide`}
                    legacyBehavior
                  >
                    <a rel="noopener noreferrer">
                      <span>Crazysales APP</span>
                    </a>
                  </Link>
                </div>
              </div>

              <div className={styles['top-head']}>
                <SearchBar categoryData={categoryData} />

                <div className={styles['tools-bar']}>
                  <div className={styles['my-cart-box']} onMouseEnter={dealWithMiniCart}>
                    <Link
                      href={`/viewcart`}
                      legacyBehavior
                    >
                      <a rel="noopener noreferrer" style={{ display: 'block' }}>
                        <div className={styles['my-cart']}>
                          <i className={`iconfont csicon-shopping-cart ${styles['tool-icon']}`}></i>
                          <div className={styles['cart-info']}>
                            <div className={`${styles['cart-num']} ${count == 0 && styles['gray']}`}>{count}</div>
                            <div className={styles['cart-title']}>Cart</div>
                          </div>
                        </div>
                      </a>
                    </Link>
                    <div className={styles['my-cart-hover-box']}>
                      {
                        count == 0
                        &&
                        <div className={styles['empty-cart']}>
                          You have no items in purchase list. Time to crazy shopping.
                        </div>
                      }
                      {
                        count > 0
                        &&
                        <div className={styles['minicar-box']}>
                          <div className={styles['minicar-list']}>
                            {
                              minicarList.length
                                ?
                                minicarList.map((item: ArrayItem, index: number) => {
                                  return (
                                    <div className={styles['minicar-list-item']} key={index}>
                                      <div className={styles['minicar-list-item-image']}>
                                        <Image
                                          className={styles['goods-image']}
                                          alt="goods"
                                          width={58}
                                          height={58}
                                          src={`//img.crazysales.com.au/products_pictures/${item.pic}`}
                                        />
                                      </div>
                                      <div className={styles['minicar-list-item-info']}>
                                        <div className={styles['goodsName']}>
                                          {item.name}
                                        </div>
                                        <div className={styles['goodsValue']}>
                                          <div className={styles['qty']}>Qty: {item.Quantity}</div>
                                          <div className={styles['price']}>${item.Price}</div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                                :
                                <Skeleton active style={{ paddingBottom: "16px" }} />
                            }
                          </div>

                          <div className={styles['minicar-total']}>
                            <div className={styles['selectItems']}>Total ({minicarList.length} items)</div>
                            <div className={styles['totalValue']}>${carInfo?.subTotal}</div>
                          </div>

                          <div className={styles['valueTips']}>Shipping calculated at checkout</div>

                          <div className={styles['minicar-config']}>
                            <Button href='/viewcart' className={`${styles['minicar-config-btn']} ${styles['viewcar-btn']}`}>View Cart</Button>
                            <Button href='/checkout/express' className={`${styles['minicar-config-btn']} ${styles['checkout-btn']}`}>Checkout</Button>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  {
                    isLogin
                      ?
                      <div className={styles['wish-list']} onClick={() => handlePageJump(`/myaccount/wishlist`)}>
                        <i className={`iconfont csicon-wishlist ${styles['tool-icon']}`}></i>
                        <div className={styles['cart-info']}>
                          <div className={styles['cart-title']}>Wish List</div>
                        </div>
                      </div>
                      :
                      <Link
                        href={`/Login?r=/myaccount/wishlist`}
                        legacyBehavior
                      >
                        <a rel="noopener noreferrer" style={{ display: 'block' }}>
                          <div className={styles['wish-list']}>
                            <i className={`iconfont csicon-wishlist ${styles['tool-icon']}`}></i>
                            <div className={styles['cart-info']}>
                              <div className={styles['cart-title']}>Wish List</div>
                            </div>
                          </div>
                        </a>
                      </Link>
                  }

                  <div className={styles['account']}>
                    <i className={`iconfont csicon-user ${styles['tool-icon']}`}></i>
                    <div className={styles['account-info']} >
                      {
                        isLogin
                          ?
                          <>
                            <div className={styles['login-link']}>
                              <span className={styles['']}>Hi, {firstname}</span>
                            </div>
                            <Link
                              href='/myaccount'
                              legacyBehavior
                            >
                              <a rel="noopener noreferrer">
                                <span className={styles['account-title']} style={{ color: '#e60500' }}>My Crazysales</span>
                              </a>
                            </Link>
                          </>
                          :
                          <>
                            <div className={styles['login-link']}>
                              <Link
                                href={`/login?f=l`}
                                legacyBehavior
                              >
                                <a rel="noopener noreferrer">
                                  <span className={styles['signIn']}>Sign In</span>
                                </a>
                              </Link>
                              <span>  |  </span>
                              <Link
                                href={`/login?f=r`}
                                legacyBehavior
                              >
                                <a rel="noopener noreferrer">
                                  <span className={styles['join']}>Join</span>
                                </a>
                              </Link>
                            </div>
                            <div className={styles['account-title']}>My Crazysales</div>
                          </>
                      }
                    </div>

                    {/* hover */}
                    <div className={styles['more_acc']}>
                      <div className={styles['uinput']}>
                        {
                          isLogin
                            ?
                            <a href={`${apiDomain}/login/logout`} onClick={removeToken} rel="nofollow" style={{ marginTop: '20px' }}>Sign Out</a>
                            :
                            <>
                              <div>Welcome to Crazysales.com.au</div>
                              <a href={`${apiDomain}/login?f=l`} rel="nofollow">Sign In</a>
                              <div>New Customer?</div>
                              <a href={`${apiDomain}/login?f=r`} rel="nofollow" className={styles['join']}>Join Free</a>
                            </>
                        }
                      </div>
                      <div className={styles['acclis']}>
                        <a href={`${apiDomain}/myaccount`} rel="nofollow">My Account</a>
                        <a href={`${apiDomain}/myaccount/order`} rel="nofollow">View Orders</a>
                        <a href={`${apiDomain}/myaccount/wishlist`} rel="nofollow">My Wishlist</a>
                        <a href={`${apiDomain}/myaccount`} rel="nofollow">Contact Information</a>
                        <a href={`${apiDomain}/myaccount/address`} rel="nofollow">Manage Address</a>
                        <a href={`${apiDomain}/myaccount/credits`} rel="nofollow">CrazyPoints &amp; credits</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
